export const links = [
  {
    name: "Noteboard",
    link: "noteboard",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"></path></svg>`,
  },
  {
    name: "Notebook",
    link: "notebook",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 96C0 43 43 0 96 0h96V190.7c0 13.4 15.5 20.9 26 12.5L272 160l54 43.2c10.5 8.4 26 .9 26-12.5V0h32 32c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32H384 96c-53 0-96-43-96-96V96zM64 416c0 17.7 14.3 32 32 32H352V384H96c-17.7 0-32 14.3-32 32z"></path></svg>`,
  },
  {
    name: "Present Canva",
    link: "present",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h4l-1.8 2.4 1.6 1.2 2.7-3.6h3l2.7 3.6 1.6-1.2L16 18h4c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM5 13h4v2H5v-2z"></path></svg>`,
  },
];

export const widgetslist = [
  {
    name: "Banner",
    desc: "Banner Component displays your name and a Overview.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M131.79,69.65l-43.63,96A4,4,0,0,1,84.52,168H28.23a8.2,8.2,0,0,1-6.58-3.13,8,8,0,0,1,.43-10.25L57.19,116,22.08,77.38a8,8,0,0,1-.43-10.26A8.22,8.22,0,0,1,28.23,64h99.92A4,4,0,0,1,131.79,69.65ZM237.56,42.24A8.3,8.3,0,0,0,231.77,40H168a8,8,0,0,0-7.28,4.69l-42.57,93.65a4,4,0,0,0,3.64,5.66h57.79l-34.86,76.69a8,8,0,1,0,14.56,6.62l80-176A8,8,0,0,0,237.56,42.24Z"></path></svg>`,
  },
  {
    name: "Recent Cards",
    desc: "Card Component displays recently created Cards from Noteboard.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M408 16H104a24 24 0 0 0-24 24v432a24 24 0 0 0 24 24h304a24 24 0 0 0 24-24V40a24 24 0 0 0-24-24zm-61.1 296.77a43 43 0 1 1-40.71-40.71 43 43 0 0 1 40.71 40.71zM192 64h128v32H192zm192 384H224v-24.6c0-32.72 53.27-49.21 80-49.21s80 16.49 80 49.21z"></path></svg>`,
  },
  {
    name: "NoteCard Counts",
    desc: "Displays the total number of note cards that have been created.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512"  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z"></path></svg>`,
  },
  {
    name: "Calendar & Time",
    desc: "Provides a calendar view with the current date and time.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM2.545 3h10.91c.3 0 .545.224.545.5v1c0 .276-.244.5-.546.5H2.545C2.245 5 2 4.776 2 4.5v-1c0-.276.244-.5.545-.5"></path></svg>`,
  },
  {
    name: "Stopwatch",
    desc: "A simple stopwatch to measure elapsed time.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0"></path></svg>`,
  },
  {
    name: "Calculator",
    desc: "A basic calculator for performing arithmetic operations.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M400 0H48C22.4 0 0 22.4 0 48v416c0 25.6 22.4 48 48 48h352c25.6 0 48-22.4 48-48V48c0-25.6-22.4-48-48-48zM128 435.2c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm0-128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8v-38.4c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v38.4zm128 128c0 6.4-6.4 12.8-12.8 12.8h-38.4c-6.4 0-12.8-6.4-12.8-12.8V268.8c0-6.4 6.4-12.8 12.8-12.8h38.4c6.4 0 12.8 6.4 12.8 12.8v166.4zm0-256c0 6.4-6.4 12.8-12.8 12.8H76.8c-6.4 0-12.8-6.4-12.8-12.8V76.8C64 70.4 70.4 64 76.8 64h294.4c6.4 0 12.8 6.4 12.8 12.8v102.4z"></path></svg>`,
  },
  {
    name: "Embed Cards",
    desc: "Allows embedding Note Cards as a Widget.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224,104v96a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V104A16,16,0,0,1,48,88H208A16,16,0,0,1,224,104ZM56,72H200a8,8,0,0,0,0-16H56a8,8,0,0,0,0,16ZM72,40H184a8,8,0,0,0,0-16H72a8,8,0,0,0,0,16Z"></path></svg>`,
  },
  {
    name: "Maps",
    desc: "Integrates map functionality to display locations and routes.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.81 7.54 24.2 0C331.7 284.8 414 182.3 414 126 414 56.41 357.6 0 288 0zm0 168c-23.25 0-42-18.75-42-42s18.75-42 42-42 42 18.75 42 42-18.7 42-42 42zm276-40h-25.99c3.61 20.4 3.24 38.96 0 55.99V438.6l-128-25.6v-131c-12.7 13.8-25.5 26.8-38.2 38.8-6.91 6.48-17.22 9.26-26.92 7.5-8.47-1.57-14.91-6.72-19.38-13.01-8.34-11.45-16.83-22.83-25.21-34.1l-0.91-1.2c-8.25 11.1-16.5 22.3-24.79 33.4-4.46 6.3-10.89 11.5-19.38 13.01-9.6 1.76-20.01-1.02-26.91-7.5-12.7-12-25.5-25-38.2-38.8v131l-144 28.8V149.4c0.59-0.43 1.19-0.86 1.79-1.28 14.1-9.9 29.7-19.2 46.2-28.4C99.45 110.7 110.4 105.6 112 104.7l1.41-0.73 0.09-0.04 1.26-0.68 0.57-0.31C141.2 89.95 159.4 78.57 159.4 78.57c1.28-0.86 2.63-1.66 4.06-2.47v-0.01C182.7 66.54 193.5 60.84 193.5 60.84l1.37-0.78c3.25-1.83 6.23-3.49 8.97-5.08h0.04c1.22-0.73 2.42-1.44 3.61-2.15l0.37-0.22C222.8 44.58 240.4 32 240.4 32v424c0 7.91 7.46 14.08 15.2 14.08 1.16 0 2.34-0.13 3.53-0.4L384 448v-213.2c0.02-0.02 0.03-0.03 0.05-0.05 7.88 8.69 15.82 17.35 23.91 25.9C413.6 278.4 432 293.6 448 307.3v140.6c4.8 0.5 10.4-0.1 15.1-1.3l108.9-27.2c7.6-2.1 14-8.9 14-17.4V126c0-17.7-14.3-32-32-32z"></path></svg>`,
  },
  {
    name: "News Feed",
    desc: "Displays current news feeds, allowing users to stay updated with the latest information.",
    icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M439.91 112h-23.82a.09.09 0 0 0-.09.09V416a32 32 0 0 0 32 32 32 32 0 0 0 32-32V152.09A40.09 40.09 0 0 0 439.91 112z"></path><path d="M384 416V72a40 40 0 0 0-40-40H72a40 40 0 0 0-40 40v352a56 56 0 0 0 56 56h342.85a1.14 1.14 0 0 0 1.15-1.15 1.14 1.14 0 0 0-.85-1.1A64.11 64.11 0 0 1 384 416zM96 128a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v64a16 16 0 0 1-16 16h-64a16 16 0 0 1-16-16zm208 272H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 368h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 400zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 304h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 336zm0-64H112.45c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 112 240h191.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 272zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 240 176h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 208zm0-64h-63.55c-8.61 0-16-6.62-16.43-15.23A16 16 0 0 1 240 112h63.55c8.61 0 16 6.62 16.43 15.23A16 16 0 0 1 304 144z"></path></svg>`,
  },
];

export const cardsList = [
  {
    name: "NoteCard",
    desc: "Jot, list, and record your thoughts.",
    url: "https://ik.imagekit.io/vituepzjm/Google-Pixel-6-PRO-scribbyy.vercel.app.png?updatedAt=1725478239541",
  },
  {
    name: "TodoCard",
    desc: "Stay on top of things with a dedicated Todo Card.",
    url: "https://ik.imagekit.io/vituepzjm/Google-Pixel-6-PRO-scribbyy.vercel.app%20(7).png?updatedAt=1725480557821",
  },
  {
    name: "SketchCard",
    desc: "Draw, doodle, and bring your ideas to life.",
    url: "https://ik.imagekit.io/vituepzjm/Google-Pixel-6-PRO-scribbyy.vercel.app%20(5).png?updatedAt=1725480557927",
  },
  {
    name: "ImageCard",
    desc: "Save and capture images for later with Image Card.",
    url: "https://ik.imagekit.io/vituepzjm/Google-Pixel-6-PRO-scribbyy.vercel.app%20(10).png?updatedAt=1725480603067",
  },
  {
    name: "NoteBook",
    desc: "Manage your notes and stories effortlessly with Notebook.",
    url: "https://ik.imagekit.io/vituepzjm/Google-Pixel-6-PRO-scribbyy.vercel.app%20(11).png?updatedAt=1725480999309",
  },
];

export const NoteboardList = [
  {
    name: "NoteCard",
    desc: "Jot, list, and record your thoughts.",
    url: "https://ik.imagekit.io/vituepzjm/NoteCard.png?updatedAt=1725566216296",
    contents: ["Title", "Paragraph", "Delimiter", "Code", "List"],
  },
  {
    name: "TodoCard",
    desc: "Stay on top of things with a dedicated Todo Card.",
    url: "https://ik.imagekit.io/santech/image.png?updatedAt=1725565435660",
    contents: ["Paragraph", "CheckList"],
  },
  {
    name: "SketchCard",
    desc: "Draw, doodle, and bring your ideas to life.",
    url: "https://ik.imagekit.io/vituepzjm/sketchCard.png?updatedAt=1725566103795",
    contents: ["Pen Tool", "Erase Tool", "Rules"],
  },
  {
    name: "ImageCard",
    desc: "Save and capture images for later with Image Card.",
    url: "https://ik.imagekit.io/vituepzjm/Scribby/image.png?updatedAt=1725565854958",
    contents: ["Image Uploader"],
  },
];

export const API_KEY = "AIzaSyCixBj2Sd-5TPlzWJ-iCCqCDssr7Q8lJkk";
